<template>
  <div class="page">
    <PageTitle title="角色管理"></PageTitle>
    <div class="content">
      <div class="add">
        <el-button class="tb-button" type="primary" @click="add">
          <i class="tb-icon-plus"/>新建角色
        </el-button>
      </div>
      <div class="tablebox">
        <el-table
          ref="multipleTable"
          row-key="id"
          :data="tableData"
          class="tb-table"
        >
          <el-table-column prop="roleName" label="角色"/>
          <el-table-column prop="level" label="等级（等级越小，权限越大）"/>
          <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="edit" @click="setUser(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="linkMenu(scope.row.id)">关联菜单</span>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="deleteUser(scope.row.id)">删除</span>
          </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="roleVisible"
      @close="close"
      width="500px"
    >
      <el-form :model="role" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
        <el-form-item label="用户名：" prop="name">
          <el-input v-model="role.name" placeholder="请输入角色名" style="width: 226px;"></el-input>
        </el-form-item>
        <el-form-item label="等级：">
          <el-input v-model="role.level" type="number" placeholder="等级越小，权限越大" style="width: 226px;"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="关联菜单"
      :visible.sync="linkVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="469px"
      style="max-height: 80vh; overflow-y: auto;"
    >
        <el-tree
          :data="menuList"
          show-checkbox
          check-on-click-node
          node-key="id"
          ref="tree"
          :default-checked-keys="menuChildren"
          :props="{
            children: 'children',
            label: 'menu_name'
          }"
        >
        </el-tree>
        <div slot="footer" class="dialog-footer">
          <el-button @click="linkVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveUserRole">确 定</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
export default {
  name: 'RoleList',
  components: {
    PageTitle
  },
  data() {
    return {
      isNew: false,
      roleId: '',
      tableData: [],
      role: {
        name: '',
        level: ''
      },
      rules: {
        name: [{required: true, message: '请输入角色名'}]
      },
      menuList: [],
      menuChildren: [],
      roleVisible: false,
      linkVisible: false
    }
  },
  computed: {
    title() {
      return this.isNew ? '新建角色' : '编辑角色'
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const url = 'admin/role/list'
      const data = await this.$https.get(url)
      if (!data) return
      this.tableData = data
    },
    add() {
      this.isNew = true
      this.roleVisible = true
    },
    setUser(row) {
      this.isNew = false
      this.role.name = row.roleName
      this.role.level = row.level
      this.role.id = row.id
      this.roleVisible = true
    },
    deleteUser(id) {
      this.$confirm('是否删除此角色?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const url = 'admin/role/delete'
        const params = { id }
        this.$https.post(url, params).then(data => {
          if (data) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          }
        })
      }).catch(() => {})
    },
    confirm() {
      const params = {
        role_name: this.role.name,
        level: this.role.level
      }
      if (this.role.id) params.id = this.role.id
      const url = 'admin/role/edit'
      this.$https.post(url, params).then(data => {
        if (data) {
          this.$message.success(this.isNew ? '添加成功' : '编辑成功')
          this.roleVisible = false
          this.getList()
        }
      })
    },
    close() {
      this.role = {
        name: '',
        level: ''
      }
    },
    linkMenu(id) {
      this.roleId = id
      const url = 'admin/role/menu/list'
      const params = {
        role_id: id
      }
      this.$https.get(url, {params}).then(data => {
        if (data) {
          const choose = (arr) => {
            if (!Array.isArray(arr)) return []
            let retArr = []
            arr.forEach(item => {
              if (item.is_selected === 1) retArr.push(item.id)
              if (item.children) choose(item.children)
            })
            return retArr
          }
          this.menuChildren = choose(data)
          this.menuList = data
          this.linkVisible = true
        }
      })
    },
    saveUserRole() {
      const keys = this.$refs.tree.getCheckedKeys()
      const params = {
        role_id: this.roleId,
        menu_ids: keys
      }
      const url = 'admin/role/menu/edit'
      this.$https.post(url, params).then(data => {
        if (data) {
          this.$message.success('设置成功')
          this.linkVisible = false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
